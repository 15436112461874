import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Image, Button } from '@chakra-ui/core';
import { navigate } from 'gatsby';

import canUseDOM from '@lib/canUseDOM';
import {
  trackReferral,
  referralSignupUrl,
  referralApiUrl,
  storeReferralToken,
} from '../lib/referral/utils';
import { cloudfrontBaseUrl } from '../lib/consts';
import { toRoundedDollars } from '../lib/toDollars';
import Helmet from 'react-helmet';

import ContentContainer from '../components/ContentContainer';
import Footer, { DesktopOnly, MobileOnly } from '../layouts/Footer';
import ZetaLogoLightmode from '../assets/ZetaLogoLightmode.svg';
import ImageRotator from '../components/referral/ImageRotator';
import { colors } from '@theme';

const Invite = ({ location }) => {
  const fullSlug = location.pathname.split(/[/ ]+/).pop();
  const slug = fullSlug[0] + fullSlug.slice(1);

  const [loaded, setLoaded] = useState(false);
  const [referrerName, setReferrerName] = useState('');
  const [referralAmount, setReferralAmount] = useState();

  const signUpUrl = referralSignupUrl();
  const apiUrl = referralApiUrl();

  const track = async () => {
    const referralData = await trackReferral({ slug });

    if (referralData.referralToken === null) {
      navigate('/joint-bank-account');
    }
    storeReferralToken(referralData.referralToken);

    if (referralData.referrerType === 'Affiliate') {
      navigate('/joint-bank-account');
    } else {
      setReferrerName(referralData.referrerName);
      setReferralAmount(referralData.referredAmountInCents);
      setLoaded(true);
    }
  };

  useEffect(() => {
    track();
  }, [apiUrl]);

  if (!loaded) return null;
  return (
    <Box>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box background={colors.veryLightGreen}>
        <Flex className="main" pt={['2rem', '8rem']} direction="column">
          <ContentContainer>
            <Flex justify="between" direction={['column', 'row']} mb={['2rem', '5rem']}>
              <Box>
                <Flex mb={['1rem', '1rem']} justify={['center', 'start']}>
                  <Image src={ZetaLogoLightmode} height="48px" />
                </Flex>

                <Flex align={['center', 'start']} direction="column">
                  <Text
                    fontWeight={700}
                    fontSize={['24px', '36px']}
                    my={['1rem', '1rem']}
                    lineHeight={1.25}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                  >
                    {toRoundedDollars(referralAmount)} is waiting for you
                  </Text>

                  <Text
                    textAlign={['center', 'left']}
                    fontSize={['16px', '18px']}
                    my={['1rem', '1rem']}
                    width={['19rem', '30rem']}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                  >
                    <strong> {referrerName} </strong> thinks you'll enjoy using Zeta to
                    manage your shared finances with your partner. As our friend's friend,
                    you'll get a cash bonus when you fund your account with direct
                    deposit.
                  </Text>

                  <Button
                    rounded={10}
                    onClick={() => {
                      if (canUseDOM) {
                        window.location.href = signUpUrl;
                      }
                    }}
                    height="40px"
                    width="10rem"
                    px={[0, 10]}
                    mt={['2rem', '1rem']}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                    style={{
                      backgroundColor: colors.lightGreen,
                      color: colors.iceberg,
                    }}
                    _hover={{
                      backgroundColor: `${colors.darkGreenHover} !important`,
                      textColor: `${colors.iceberg} !important`,
                    }}
                  >
                    <strong>Earn {toRoundedDollars(referralAmount)} today</strong>
                  </Button>

                  <Text
                    mt={['1rem', 0]}
                    pt={2}
                    fontSize="14px"
                    textAlign="center"
                    width="14rem"
                    fontStyle="italic"
                    fontWeight={400}
                    color={colors.boulder}
                  >
                    By submitting and continuing you agree to the{' '}
                    <a
                      style={{ color: colors.greenPea }}
                      target="_blank"
                      href="/referral-terms-and-conditions"
                    >
                      referral agreement
                    </a>
                    .
                  </Text>
                </Flex>
              </Box>
              <Flex
                justify={['center', 'left']}
                mt={['2rem', '2rem']}
                pl={[0, '4rem']}
                lineHeight="18px"
              >
                <ImageRotator />
              </Flex>
            </Flex>
          </ContentContainer>
        </Flex>
      </Box>
      <Flex mx={[0, '8rem']} direction="column">
        <Flex mt={['1.5rem', '5rem']} justify="center">
          <Text color={colors.primary} fontSize={['26px', '28px']} fontWeight={700}>
            Zeta gives you
          </Text>
        </Flex>
        <Flex
          mt={['2rem', '3rem']}
          direction={['column', 'row']}
          justify={['none', 'space-evenly']}
        >
          <Flex align={['center', 'none']} mb={['3rem', '1rem']} direction="column">
            <Image
              mb={['1rem', '2rem']}
              height="70px"
              src={`${cloudfrontBaseUrl}/invite/transactions.svg`}
            />
            <Text fontSize={['16px', '18px']} align="center" width={['18rem', '20rem']}>
              A joint account with two debit cards and zero monthly fees. It works
              alongside your individual accounts, or replaces them - your choice.
            </Text>
          </Flex>
          <Flex align={['center', 'none']} mb={['2rem', 0]} direction="column">
            <Image
              mb={['1rem', '2rem']}
              height="70px"
              src={`${cloudfrontBaseUrl}/invite/movemoney.svg`}
            />
            <Text fontSize={['16px', '18px']} align="center" width="22rem">
              Automation to set aside money for bills, specific goals, or spending cash.
            </Text>
          </Flex>
          <Flex align={['center', 'none']} direction="column">
            <Image
              mb={['1rem', '2rem']}
              height={['50px', '70px']}
              src={`${cloudfrontBaseUrl}/invite/fistbump.svg`}
            />
            <Text fontSize={['16px', '18px']} align="center" width={['16rem', '20rem']}>
              Instant transaction notifications, spending trackers and easier
              communication with your partner.
            </Text>
          </Flex>
        </Flex>
        <Flex
          justify="center"
          alignItems="center"
          mb={['2.5rem', '4rem']}
          mt={['2rem', '4rem']}
        >
          <Flex
            rounded={15}
            justify="center"
            backgroundColor={colors.yellowLight}
            direction="column"
            align="center"
            width={['20rem', '48rem']}
          >
            <Text
              pt={['2rem', '4rem']}
              align="center"
              fontSize={['20px', '24px']}
              lineHeight={['34px', '40px']}
              width={['15rem', '30rem']}
              sx={
                loaded
                  ? { opacity: 1, transition: 'all 250ms linear' }
                  : { opacity: 0, transition: 'all 250ms linear 2s' }
              }
            >
              Set up a qualifying direct deposit and redeem your{' '}
              <strong>{toRoundedDollars(referralAmount)} bonus.</strong>
            </Text>

            <Button
              rounded={10}
              onClick={() => {
                window.location.href = signUpUrl;
              }}
              height="40px"
              mt={['2rem', '1.5rem']}
              mb={['3rem', '4rem']}
              px={[0, 10]}
              width="10rem"
              style={{
                backgroundColor: colors.lightGreen,
                color: colors.iceberg,
              }}
              _hover={{
                backgroundColor: `${colors.darkGreenHover} !important`,
                textColor: `${colors.iceberg} !important`,
              }}
            >
              <strong>Get started</strong>
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Box>
  );
};

export default Invite;
